import { Chip } from "@mui/material";
import React from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

type PositionChangeIconProps = {
  positionChange: number;
};

export const PositionChangeChip = ({
  positionChange,
}: PositionChangeIconProps) => {
  if (positionChange > 0) {
    return (
      <Chip
        icon={<ArrowUpwardIcon fontSize="medium" />}
        label={positionChange}
        sx={{ ml: 1 }}
      />
    );
  }
  return null;
};
