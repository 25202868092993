import { createSelector } from "@reduxjs/toolkit";
import { selectFinishedGames } from "../games/games.feature";
import { Game } from "../../types/Game.type";
import { selectPlayers } from "../players/players.feature";
import { Standing } from "../../types/Standing.type";
import {
  selectStandingsSorting,
  StandingsSortingOptions,
} from "../sorting/sorting.feature";
import { Player } from "../../types/Player.type";

const selectStandings = createSelector(
  selectFinishedGames,
  selectPlayers,
  (games, players) => {
    const playersMap = new Map<string, Player>();
    players.forEach((player) => {
      playersMap.set(player.id, player);
    });

    const playerGamesMap = new Map<Player, Game[]>();
    players.forEach((player) => {
      playerGamesMap.set(player, []);
    });

    games.forEach((game) => {
      const homePlayerA = playersMap.get(game.home.playerA);
      const homePlayerB = playersMap.get(game.home.playerB);
      const awayPlayerA = playersMap.get(game.away.playerA);
      const awayPlayerB = playersMap.get(game.away.playerB);

      if (homePlayerA) {
        playerGamesMap.get(homePlayerA)?.push(game);
      }
      if (homePlayerB) {
        playerGamesMap.get(homePlayerB)?.push(game);
      }
      if (awayPlayerA) {
        playerGamesMap.get(awayPlayerA)?.push(game);
      }
      if (awayPlayerB) {
        playerGamesMap.get(awayPlayerB)?.push(game);
      }
    });

    const standings: Standing[] = [];

    playerGamesMap.forEach((games, player) => {
      const points = games.reduce((total, game) => {
        return total + calculatePoints(game, player);
      }, 0);

      standings.push({
        id: player.id,
        points,
        games: games.length,
        positionChange: 0,
      });
    });

    return standings.sort(
      (a: Standing, b: Standing) => b.points - a.points || b.games - a.games,
    );
  },
);

const selectStandingsForLastRound = createSelector(
  selectFinishedGames,
  selectPlayers,
  (games, players) => {
    const playersMap = new Map<string, Player>();
    players.forEach((player) => {
      playersMap.set(player.id, player);
    });

    const playerGamesMap = new Map<Player, Game[]>();
    players.forEach((player) => {
      playerGamesMap.set(player, []);
    });

    const lastRound = Math.max(...games.map((game) => game.round));

    games
      .filter((game) => game.round < lastRound)
      .forEach((game) => {
        const homePlayerA = playersMap.get(game.home.playerA);
        const homePlayerB = playersMap.get(game.home.playerB);
        const awayPlayerA = playersMap.get(game.away.playerA);
        const awayPlayerB = playersMap.get(game.away.playerB);

        if (homePlayerA) {
          playerGamesMap.get(homePlayerA)?.push(game);
        }
        if (homePlayerB) {
          playerGamesMap.get(homePlayerB)?.push(game);
        }
        if (awayPlayerA) {
          playerGamesMap.get(awayPlayerA)?.push(game);
        }
        if (awayPlayerB) {
          playerGamesMap.get(awayPlayerB)?.push(game);
        }
      });

    const standings: Standing[] = [];

    playerGamesMap.forEach((games, player) => {
      const points = games.reduce((total, game) => {
        return total + calculatePoints(game, player);
      }, 0);

      standings.push({
        id: player.id,
        points,
        games: games.length,
      });
    });

    return standings.sort(
      (a: Standing, b: Standing) => b.points - a.points || b.games - a.games,
    );
  },
);

export const selectSortedStandings = createSelector(
  selectStandings,
  selectStandingsForLastRound,
  selectStandingsSorting,
  (standings, standingsForLastRound, sorting) => {
    // Merge total standings with last round standings
    standings.forEach((standing, index) => {
      const lastRoundStanding = standingsForLastRound.find(
        (s) => s.id === standing.id,
      );
      if (lastRoundStanding) {
        standing.positionChange =
          standingsForLastRound.indexOf(lastRoundStanding) - index;
      }
    });

    switch (sorting) {
      case StandingsSortingOptions.Games:
        return standings.sort(
          (a: Standing, b: Standing) =>
            a.games - b.games || b.points - a.points,
        );
      default:
        return standings.sort(
          (a: Standing, b: Standing) =>
            b.points - a.points || a.games - b.games,
        );
    }
  },
);

const calculatePoints = (game: Game, player: Player) => {
  if (game.home.playerA === player.id || game.home.playerB === player.id) {
    if (game.home.score > game.away.score) {
      if (player.joker === game.round) {
        return 2 * (game.home.score - game.away.score);
      }
      return game.home.score - game.away.score;
    }
  }
  if (game.away.playerA === player.id || game.away.playerB === player.id) {
    if (game.away.score > game.home.score) {
      if (player.joker === game.round) {
        return 2 * (game.away.score - game.home.score);
      }
      return game.away.score - game.home.score;
    }
  }
  return 0;
};
