import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { Game } from "../../types/Game.type";
import { selectTournamentRound } from "../tournaments/tournaments.feature";

const gamesAdapter = createEntityAdapter<Game>({});

const initialState = gamesAdapter.getInitialState({
  status: "idle",
});

export const gamesSlice = createSlice({
  name: "games",
  initialState,
  reducers: {
    addGameFromFirestore(state, action) {
      gamesAdapter.addOne(state, action.payload);
    },
    updateGameFromFirestore(state, action) {
      gamesAdapter.updateOne(state, action.payload);
    },
    removeGameFromFirestore(state, action) {
      gamesAdapter.removeOne(state, action.payload);
    },
    resetGames(state) {
      gamesAdapter.removeAll(state);
    },
  },
});

export const {
  selectAll: selectGames,
  selectById: selectGameById,
  selectIds: selectGameIds,
} = gamesAdapter.getSelectors((state: RootState) => state.games);

export const selectGameIdsByRound = createSelector(
  selectGames,
  selectTournamentRound,
  (games, round) => {
    return games.filter((game) => game.round === round).map((game) => game.id);
  },
);

export const selectFinishedGames = createSelector(selectGames, (games) => {
  return games.filter((game) => game.finished);
});

export const selectUpcomingGames = createSelector(selectGames, (games) => {
  return games.filter((game) => !game.finished);
});

export const selectRoundsPlayed = createSelector(
  selectFinishedGames,
  (games) => {
    return games.length > 0
      ? Math.max(...Object.values(games.map((game) => game.round)))
      : 0;
  },
);

export const selectHighScoreGames = createSelector(
  selectFinishedGames,
  (games) => {
    const gamesWithScores = games.map((game) => {
      const score = Math.abs(game.home.score - game.away.score);
      return {
        score,
        ...game,
      };
    });

    const highScore = Math.max(...gamesWithScores.map((game) => game.score));
    return gamesWithScores.filter((game) => game.score === highScore);
  },
);

export const {
  addGameFromFirestore,
  updateGameFromFirestore,
  removeGameFromFirestore,
} = gamesSlice.actions;

export default gamesSlice.reducer;
