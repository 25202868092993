import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export const PlayersSortingOptions = {
  Group: "Group",
  Handycap: "Handycap",
  Name: "Name",
};

export const StandingsSortingOptions = {
  Games: "Games",
  Points: "Points",
};

const initialState = {
  players: PlayersSortingOptions.Name,
  standings: StandingsSortingOptions.Points,
};

export const sortingSlice = createSlice({
  name: "sorting",
  initialState,
  reducers: {},
});

export const selectStandingsSorting = (state: RootState) =>
  state.sorting.standings;

export default sortingSlice.reducer;
