import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { Tournament } from "../../types/Tournament.type";

export const DisplayOptions = {
  Games: "Games",
  Standings: "Standings",
  Tournament: "Tournament",
};

const tournamentsAdapter = createEntityAdapter<Tournament>({});

const initialState = tournamentsAdapter.getInitialState();

export const tournamentsSlice = createSlice({
  name: "tournaments",
  initialState,
  reducers: {
    addTournamentFromFirestore(state, action) {
      tournamentsAdapter.addOne(state, action.payload);
    },
    updateTournamentFromFirestore(state, action) {
      tournamentsAdapter.updateOne(state, action.payload);
    },
    removeTournamentFromFirestore(state, action) {
      tournamentsAdapter.removeOne(state, action.payload);
    },
  },
});

export const { selectAll: selectTournaments } = tournamentsAdapter.getSelectors(
  (state: RootState) => state.tournaments,
);

export const selectTournament = createSelector(
  selectTournaments,
  (tournaments) => {
    return tournaments.find((tournament) => tournament.active);
  },
);

export const selectTournamentId = createSelector(
  selectTournament,
  (tournament) => tournament?.id,
);

export const selectTournamentDisplay = createSelector(
  selectTournament,
  (tournament) => tournament?.display,
);

export const selectTournamentName = createSelector(
  selectTournament,
  (tournament) => tournament?.name,
);

export const selectTournamentRound = createSelector(
  selectTournament,
  (tournament) => tournament?.round,
);

export const {
  addTournamentFromFirestore,
  updateTournamentFromFirestore,
  removeTournamentFromFirestore,
} = tournamentsSlice.actions;

export default tournamentsSlice.reducer;
