import { Container } from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import { RoundTable } from "../round/RoundTable";
import { StandingsTable } from "../standings/StandingsTable";
import { TournamentOverview } from "../tournaments/TournamentOverview";
import {
  DisplayOptions,
  selectTournamentDisplay,
} from "../tournaments/tournaments.feature";

export const Display = () => {
  const display = useAppSelector(selectTournamentDisplay);

  return (
    <Container maxWidth={false} sx={{ padding: 2 }}>
      {display === DisplayOptions.Games && <RoundTable />}
      {display === DisplayOptions.Standings && <StandingsTable />}
      {display === DisplayOptions.Tournament && <TournamentOverview />}
    </Container>
  );
};
