import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { Player } from "../../types/Player.type";
import { selectHighScoreGames } from "../games/games.feature";

const playersAdapter = createEntityAdapter<Player>({});

const initialState = playersAdapter.getInitialState({
  status: "idle",
});

export const playersSlice = createSlice({
  name: "players",
  initialState,
  reducers: {
    addPlayerFromFirestore(state, action) {
      playersAdapter.addOne(state, action.payload);
    },
    updatePlayerFromFirestore(state, action) {
      playersAdapter.updateOne(state, action.payload);
    },
    removePlayerFromFirestore(state, action) {
      playersAdapter.removeOne(state, action.payload);
    },
    resetPlayers(state) {
      playersAdapter.removeAll(state);
    },
  },
});

export const {
  selectAll: selectPlayers,
  selectById: selectPlayerById,
  selectIds: selectPlayerIds,
} = playersAdapter.getSelectors((state: RootState) => state.players);

const selectAvailablePlayers = createSelector(selectPlayers, (players) => {
  return players
    .filter((player) => player.available)
    .sort((a, b) => sortByName(a, b));
});

export const selectAvailablePlayerIds = createSelector(
  selectAvailablePlayers,
  (players) => {
    return players.map((player) => player.id);
  },
);

const sortByName = (a: Player, b: Player) => {
  return (
    a.prename.localeCompare(b.prename) || a.surname.localeCompare(b.surname)
  );
};

export const selectHighScorePlayers = createSelector(
  selectHighScoreGames,
  (games) => {
    const playerIds: string[] = [];
    games.forEach((game) => {
      if (game.home.score > game.away.score) {
        playerIds.push(game.home.playerA);
        playerIds.push(game.home.playerB);
      } else if (game.home.score < game.away.score) {
        playerIds.push(game.away.playerA);
        playerIds.push(game.away.playerB);
      }
    });
    return playerIds;
  },
);

export const selectClubsFromPlayers = createSelector(
  selectPlayers,
  (players) => {
    return players
      .map((player) => player.club)
      .filter((club) => club !== null)
      .filter((value, index, array) => array.indexOf(value) === index);
  },
);

export const selectRemainingJoker = createSelector(selectPlayers, (players) => {
  return players.filter((player) => player.joker === 0);
});

export const {
  addPlayerFromFirestore,
  updatePlayerFromFirestore,
  removePlayerFromFirestore,
} = playersSlice.actions;

export default playersSlice.reducer;
