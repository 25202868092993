import { collection, onSnapshot, query } from "firebase/firestore";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import {
  addGameFromFirestore,
  removeGameFromFirestore,
  updateGameFromFirestore,
} from "../features/games/games.feature";
import {
  addPlayerFromFirestore,
  removePlayerFromFirestore,
  updatePlayerFromFirestore,
} from "../features/players/players.feature";
import {
  addTournamentFromFirestore,
  removeTournamentFromFirestore,
  selectTournamentId,
  updateTournamentFromFirestore,
} from "../features/tournaments/tournaments.feature";
import type { AppDispatch, RootState } from "./store";
import { auth, db } from "@nikolausturnier/shared/src/firebase";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type ThunkApi = { state: RootState };

export const useTournamentStore = () => {
  const dispatch = useAppDispatch();
  const uid = auth.currentUser?.uid;
  const q = query(collection(db, `${uid}`));
  const unsubscribe = onSnapshot(q, (snapshot) => {
    snapshot.docChanges().forEach((change) => {
      if (change.type === "added") {
        dispatch(
          addTournamentFromFirestore({
            ...change.doc.data(),
            id: change.doc.id,
          }),
        );
      }
      if (change.type === "modified") {
        dispatch(
          updateTournamentFromFirestore({
            id: change.doc.id,
            changes: change.doc.data(),
          }),
        );
      }
      if (change.type === "removed") {
        dispatch(removeTournamentFromFirestore(change.doc.id));
      }
    });
  });
  return () => unsubscribe();
};

export const usePlayerStore = () => {
  const dispatch = useAppDispatch();
  const uid = auth.currentUser?.uid;
  const tid = useAppSelector(selectTournamentId);
  const q = query(collection(db, `${uid}/${tid}/players`));
  const unsubscribe = onSnapshot(q, (snapshot) => {
    snapshot.docChanges().forEach((change) => {
      if (change.type === "added") {
        dispatch(
          addPlayerFromFirestore({ ...change.doc.data(), id: change.doc.id }),
        );
      }
      if (change.type === "modified") {
        dispatch(
          updatePlayerFromFirestore({
            id: change.doc.id,
            changes: change.doc.data(),
          }),
        );
      }
      if (change.type === "removed") {
        dispatch(removePlayerFromFirestore(change.doc.id));
      }
    });
  });
  return () => unsubscribe();
};

export const useGamesStore = () => {
  const dispatch = useAppDispatch();
  const uid = auth.currentUser?.uid;
  const tid = useAppSelector(selectTournamentId);
  const q = query(collection(db, `${uid}/${tid}/games`));
  const unsubscribe = onSnapshot(q, (snapshot) => {
    snapshot.docChanges().forEach((change) => {
      if (change.type === "added") {
        dispatch(
          addGameFromFirestore({ ...change.doc.data(), id: change.doc.id }),
        );
      }
      if (change.type === "modified") {
        dispatch(
          updateGameFromFirestore({
            id: change.doc.id,
            changes: change.doc.data(),
          }),
        );
      }
      if (change.type === "removed") {
        dispatch(removeGameFromFirestore(change.doc.id));
      }
    });
  });
  return () => unsubscribe();
};
