import { Typography, TypographyTypeMap } from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import { JokerChip } from "../../components/chips/JokerChip";
import { selectPlayerById } from "./players.feature";

type PlayerProps = {
  id: string;
  round?: number;
  variant?: TypographyTypeMap["props"]["variant"];
};

export const Player = ({ id, round, variant = "inherit" }: PlayerProps) => {
  const player = useAppSelector((state) => selectPlayerById(state, id));

  return (
    <>
      {player ? (
        <Typography variant={variant}>
          {player.prename} {player.surname} [{player.handycap > 0 && "+"}
          {player.handycap}]
          {player.joker === round && <JokerChip color="primary" />}
        </Typography>
      ) : null}
    </>
  );
};
