import { User } from "firebase/auth";
import { useTournamentStore } from "../app/hooks";
import { Header } from "./Header";
import { Main } from "./Main";
import { useRouterAnalytics } from "@nikolausturnier/shared/src/hooks";

type UserProps = {
  user: User;
};

export const Authenticated = ({ user }: UserProps) => {
  useTournamentStore();
  useRouterAnalytics();

  return (
    <div className="App">
      <header className="App-header">
        <Header user={user} />
      </header>
      <main className="App-body">
        <Main />
      </main>
      <footer className="App-footer"></footer>
    </div>
  );
};
