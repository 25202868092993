import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import { Player } from "../players/Player";
import { selectHighScorePlayers } from "../players/players.feature";
import { Label } from "../../types/Label.type";
import { EmptyState } from "../../components/EmptyState";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import { selectSortedStandings } from "./standings.feature";
import React from "react";
import { PositionChangeChip } from "../../components/chips/PositionChangeChip";
import { HighScoreChip } from "../../components/chips/HighScoreChip";

export const StandingsTable = () => {
  const standings = useAppSelector(selectSortedStandings);
  const highScorePlayers = useAppSelector(selectHighScorePlayers);

  if (standings.length > 0) {
    return (
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <Typography sx={{ fontWeight: 600 }} variant="h4">
                  Player
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={{ fontWeight: 600 }} variant="h4">
                  Games
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={{ fontWeight: 600 }} variant="h4">
                  Points
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {standings.map((standing, index) => (
              <TableRow key={standing.id}>
                <TableCell>
                  <Typography sx={{ fontWeight: 600 }} variant="h4">
                    {index + 1}
                    {standing.positionChange != null && (
                      <PositionChangeChip
                        positionChange={standing.positionChange}
                      />
                    )}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Box display="flex" alignItems="center">
                    <Player id={standing.id} variant="h4" />
                    {highScorePlayers.includes(standing.id) && (
                      <HighScoreChip />
                    )}
                  </Box>
                </TableCell>
                <TableCell>
                  <Typography variant="h4">{standing.games}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h4">{standing.points}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    return (
      <EmptyState
        icon={<FormatListNumberedIcon fontSize="large" />}
        label={Label.Standing}
      />
    );
  }
};
