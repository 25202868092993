import { Route, Routes } from "react-router-dom";
import { useGamesStore, usePlayerStore } from "../app/hooks";
import { Display } from "../features/display/Display";

export const Main = () => {
  usePlayerStore();
  useGamesStore();

  return (
    <Routes>
      <Route path="/" element={<Display />} />
    </Routes>
  );
};
